
import React from "react";
import { Timeline } from "../../Components/magicui/timeline";
import { GuidedProjectDiscovery } from '../FeaturesSection/GuidedProjectDiscovery';
import { BentoCard , BentoGrid} from '../../Components/magicui/bento-grid';
import { PlaceholdersAndVanishInput } from '../../Components/magicui/cool-input';

const Journey = () => {

  const data = [
    {
      title: "Idea",
      content: (
        <div>
          <p className="text-neutral-800 dark:text-neutral-200 text-md md:text-lg font-normal mb-8">
            Describe your idea in a few sentences to our AI Agent
          </p>
        
          
          <img
              src="/req1.png"
              alt="hero template"
              height={500}
              className="rounded-lg object-cover shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
            />
        </div>
      ),
    },
    {
      title: "Prototype",
      content: (
        <div>
          <p className="text-neutral-800 dark:text-neutral-200 text-md md:text-lg font-normal mb-8">
            Our AI Agent will design the architecture/roadmap, and give you an initial prototype, saving you <b className="text-xl">$1000's</b> before any code is written.
          </p>

          <div className="grid grid-cols-2 gap-4">
            <img
              src="/requirements.png"
              alt="hero template"
              height={500}
              className="rounded-lg object-cover shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
            />
            <img
              src="/techstackpic.png"
              alt="feature template"
              height={500}
              className="rounded-lg object-cover shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
            />
            <img
              src="/arch.png"
              alt="bento template"
              height={500}
              className="rounded-lg object-cover shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
            />
            <img
              src="/req4.png"
              alt="cards template"
              height={500}
              className="rounded-lg w-full shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Budget",
      content: (
        <div>
          <p className="text-neutral-800 dark:text-neutral-200 text-lg md:text-xl font-normal mb-4">
            Then, we will help you filter the scope of your MVP and prioritize the most important features to launch within your budget.
          </p>

      
            <img
              src="/req3.png"
              alt="cards template"
              height={500}
              className="rounded-lg shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
            />
  
        </div>
      ),
    },
    {
      title: "Developer Matching",
      content: (
        <div>
          <p className="text-neutral-800 dark:text-neutral-200 text-lg md:text-xl font-normal mb-4">
            When you are ready, we will instantly match you with a professionally-vetted development team that fits within your budget and has the skillset necessary to deliver your exact product requirements.
          </p>

      
            <img
              src="/match.png"
              alt="cards template"
              height={500}
              className="rounded-lg shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
            />
  
        </div>
      ),
    },
  ];
  return (
    <div className="w-full dark">
   
     <Timeline data={data} />
   
    </div>
  );
}
export default Journey;