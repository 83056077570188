export default function Example() {
    return (
      <div className="bg-transparent py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-2xl font-semibold text-white">Trusted by the World’s Most Innovative Startup Ecosystems</h2>
          <div className="mx-auto mt-10 flex justify-center max-w-lg sm:max-w-xl lg:mx-0 lg:max-w-none">
            <div className="mx-auto grid grid-cols-2 items-center gap-x-8 gap-y-10 sm:grid-cols-2 sm:gap-x-10 lg:grid-cols-2">
              <img
                alt="venture miami"
                src="/vm.svg"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="Wharton cypher"
                src="/cyph.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
            </div>
          </div>
        </div>
      </div>
    );
}