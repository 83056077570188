import { useState, useEffect, useRef } from 'react';
import { submitHeroExperience } from '../../../redux/actions/solo/submitHeroExperience';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { getProjectByLink } from '../../../redux/actions/solo/getProjectByLink';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { PlaceholdersAndVanishInput } from '../../Components/magicui/cool-input';
export const InputArea = ({
    answers,
    setAnswers,
    setShowInput,
    setCountdown,
    setLoading,
    setQuestionIndex,
    questionIndex,
    matchWithDev,
    urgency,
    setUrgency,
    setMatchWithDev
}) => {
    const navigate = useNavigate();
    const [answer, setAnswer] = useState('');
    const [budget, setBudget] = useState(10000);
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;
    const dispatch = useDispatch();

    useEffect(() => {
        if (userEmail && questionIndex === 3) setAnswer(userEmail);
    }, [questionIndex, userEmail]);

    const processAnswer = async () => {
        if (questionIndex === 0 && answer.length < 1) {
            toast('Please enter an answer.');
            return;
        }
        setShowInput(false);
        setQuestionIndex(questionIndex + 1);

        if (questionIndex === 1) {
            const email = answer.trim();
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                toast('Please enter a valid email address.');
                return;
            }
            setLoading(true);
            let countdownTime = 7;
            setCountdown(countdownTime);

            let project_id;

            try {
                let countInterval = setInterval(() => {
                    if (countdownTime > 0) {
                        countdownTime--;
                        setCountdown(countdownTime);
                    } else if (project_id) {
                        navigate('/demo/' + project_id);
                        clearInterval(countInterval);
                    }
                }, 1000);

                try {
                    project_id = await dispatch(
                        submitHeroExperience({
                            answers: answers,
                            userEmail: email,
                            matchWithDev,
                            budget: budget.toString(),
                            urgency
                        })
                    );
                    dispatch(getProjectByLink(project_id));
                } catch (e) {
                    navigate('/auth');
                }
            } catch (e) {
                navigate('/auth');
            }
        } else if (questionIndex < 2) {
            setAnswers(prevAnswers => [...prevAnswers, answer]);
        }
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        checkIfMobile();

        // Add event listener
        window.addEventListener('resize', checkIfMobile);

        // Cleanup
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const placeholderText =
        questionIndex === 0 ? 'I would like to build a...' : 'steve@apple.com';

    let textAreaHeight = 'h-[200px]';
    if (questionIndex === 2) textAreaHeight = 'h-[80px]';

    return (
        <MainInput
            answer={answer}
            setAnswer={setAnswer}
            questionIndex={questionIndex}
            placeholderText={placeholderText}
            textAreaHeight={textAreaHeight}
            processAnswer={processAnswer}
        />
    );
};
const MainInput = ({
    answer,
    setAnswer,
    questionIndex,
    placeholderText,
    processAnswer,
    textAreaHeight
}) => {
    const textareaRef = useRef(null);
    const containerRef = useRef(null);

    const handleSubmit = async () => {
        setTimeout(async() => {
        if (containerRef.current) {
            containerRef.current.style.transition = 'opacity 1s';
            containerRef.current.style.opacity = '0';
            await new Promise(resolve => setTimeout(resolve, 500));
            processAnswer();
        }
    }, 500);
    };
    const placeholders = [
        "I would like to build an AI-powered personal assistant app...",
        "I would like to build an augmented reality shopping app...",
        "I would like to build a VR travel experience app...",
        "I would like to build a blockchain-based voting app..."
    ];

    return (
        <div
            ref={containerRef}
            className="flex items-center relative mx-auto max-w-xl dark"
        >
            <PlaceholdersAndVanishInput
                placeholders={questionIndex === 1 ? ["steve@apple.com"] : placeholders}
                ref={textareaRef}
                type={questionIndex === 1 ? 'email' : 'text'}
                onChange={e => setAnswer(e.target.value)}
                onSubmit={handleSubmit}
            />
            {/* <textarea
                ref={textareaRef}
                type={questionIndex === 1 ? 'email' : 'text'}
                id="mainInput"
                required
                value={answer}
                onChange={e => setAnswer(e.target.value)}
                className="h-full w-full max-h-[150px] min-h-[40px] resize-none rounded-xl bg-slate-800 p-4 pr-20 text-slate-200 py-4 flex items-center text-sm"
                placeholder={placeholderText}
                rows="1"
                style={{ lineHeight: '20px', height: '54px !important' }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit();
                    }
                }}
            /> */}
            <div className="absolute left-2 top-1/2 transform -translate-y-1/2">
                {/* <button className="text-white font-medium rounded-lg text-xs px-2 py-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                        className="inline justify-end w-5 h-4 transition-colors duration-200 ease-in-out hover:text-blue-300"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                        ></path>
                    </svg>
                </button> */}
            </div>
            {/* <button
                type="submit"
                disabled={answer === ''}
                onClick={handleSubmit}
                className={`absolute right-2 rounded-lg px-4 py-2 text-sm font-medium text-white ${
                    answer === ''
                        ? 'bg-gray-500 cursor-not-allowed'
                        : 'bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700'
                }`}
            >
                <PaperAirplaneIcon className="w-5 h-5" />
            </button> */}
        </div>
    );
};
